import { Box, Divider, FormControl, Grid, IconButton, InputAdornment, Menu, MenuItem, Modal, OutlinedInput, Switch, TextField, useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MessageIcon from "@mui/icons-material/Message";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AddLineDescriptionModal from "./AddLineDescriptionModal";
import CreateNewDescriptionModal from "./CreateNewDescriptionModal";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import AdditionalInputLine from "./AdditionalInputLine";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import { toast } from "react-hot-toast";
import { Base64 } from "js-base64";
import { origin } from "../../Config/Api-constants";
import axios from "axios";
import { activateLines, getInspectionData } from "../../Redux/Actions/CommonActions";
import Compressor from "compressorjs";
import { checkDescriptionDisabled, checkLineComplete, filterObjectsUptoLine, getImageDimensions, resizeImage } from "../../Helpers/HelperFunctions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IoMdColorPalette } from "react-icons/io";
import AssignColorIndicatorModal from "../../Pages/Inspection/AssignColorIndicatorModal";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { AiOutlineCopy } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import FilledButton from "../Button/FilledButton";
import CloseIcon from "@mui/icons-material/Close";
import { ApiCallDuplicateLine, UpdateTemplateDataSpecific } from "../../Helpers/ApiCall";
import DeleteConfirmationDialog from "../../Common/Alert/DeleteConfirmationDialog";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import DoneIcon from "@mui/icons-material/Done";

const LineDescription = ({
  line,
  templateId,
  categoryId,
  categoryData,
  template_id,
  category_id,
  category_uuid,
  lineUuid,
  render,
  action = "line",
  loader,
  setLoader,
  setLoaderText,
  setLoader2,
  lineIndex,
  isNarrative,
  inspectionResponse,
  setDuplicateLineIdx,
  setDuplicateLineHighlighted,
}) => {
  // states
  const [descriptionArray, setDescriptionArray] = useState([]);
  const [descriptionArrayCopy, setDescriptionArrayCopy] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState([]);
  const [showAddDescriptionModal, setShowAddDescriptionModal] = useState(false);
  const [tempSelectedDescription, setTempSeletedDescrition] = useState([]);
  const [showCreateDescriptionModal, setShowCreateDescriptionModal] = useState(false);
  const [additionalInputs, setAdditionalInputs] = useState([]);
  const [editAadditionalInp, setEditAadditionalInp] = useState(false);
  const [currentAdditionalInputID, setCurrentAdditionalInputID] = useState();
  const [descriptionPresent, setDescriptionPresent] = useState(false);
  const [addInputPresent, setAddInputPresent] = useState(false);
  const [addInputChanged, setAddInputChanged] = useState(false);
  const [showTemperatureModal, setShowTemperatureModal] = useState(false);
  const [showTextBoxModal, setShowTextBoxModal] = useState(false);
  const [is_saved, setISSaved] = useState(false);
  const [lineMediaData, setLineMediaData] = useState("");
  const [newEmptyDescription, setNewEmptyDescription] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [image, setImage] = useState("");
  const [descriptionData, setDescriptionData] = useState();
  const [descriptionDisable, setDescriptionDisable] = useState();
  const [lineMenuAnchorEl, setLineMenuAnchorEl] = useState(null);
  const [assignColorIndicatorLine, setAssignColorIndicatorLine] = useState();
  const [assignColorIndicatorCategory, setAssignColorIndicatorCategory] = useState(categoryData);
  const [assignColorIndicatorModal, setAssignColorIndicatorModal] = useState(false);
  const [showEditLineModal, setShowEditLineModal] = useState(false);
  const [activeEditLine, setActiveEditLine] = useState(false);
  const [editLineName, setEditLineName] = useState("");
  const [activeDeleteLine, setActiveDeleteLine] = useState();
  const [deleteLineModal, setShowDeleteLineModal] = useState(false);

  let responseCount = 0;
  let videoUploadCount = 0;
  let imageuploadCount = 0;
  let successCount = 0;
  let errorCount = 0;
  let sizeErrorCount = 0;
  const matches = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();
  const fileInputLine = useRef();
  const isLineMenuOpen = Boolean(lineMenuAnchorEl);
  // redux
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  const assignColorList = storeData?.inspectionData?.data?.inspectorFlagList?.filter((item) => item.is_deleted !== 1) || [];
  let templateData = storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId);

  const getLineDesValue = (line) => {
    let selected = line?.line_input_controls?.filter((lineData) => lineData.type === "Description")[0]?.data;
    setSelectedDescription(selected === undefined ? [] : selected);
    let additionalInp = line?.line_input_controls?.filter((lineData) => lineData.type === "Additional Input");

    if (selected?.length !== 0) setDescriptionPresent(true);
    else setDescriptionPresent(false);
    setAdditionalInputs(additionalInp);
    let DescData = line?.line_input_controls?.find((lineData) => lineData.type === "Description");
    setDescriptionData(DescData);
  };

  // add media
  async function convertBase64ToFile(base64String, filename, file, formData, fileType, currentMediaLength, orderVal, e, videoCount) {
    setLoaderText(`uploading video ${videoUploadCount + 1} of ${videoCount}`);
    const arr = base64String[0].split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    let thumbVideoFile = new File([u8arr], filename, { type: mime });
    let thumbFile;
    const { imgWidth, imgHeight } = await getImageDimensions(thumbVideoFile);
    const maxWidth = 320;
    const maxHeight = 320;
    const quality = 1;
    let newWidth, newHeight;
    if (imgWidth > imgHeight) {
      // Landscape orientation
      newHeight = maxHeight;
      newWidth = (maxHeight / imgHeight) * imgWidth;
    } else {
      // Portrait orientation
      newWidth = maxWidth;
      newHeight = (maxWidth / imgWidth) * imgHeight;
    }
    let thumbnailImage = await resizeImage(thumbVideoFile, newWidth, newHeight, quality);
    thumbFile = new File([thumbnailImage], `${thumbnailImage.name}`, { type: "image/jpeg" });
    let imgObj = {
      id: uuidv4(),
      url: null,
      name: file.name,
      size: file.size,
      type: file.type,
      edited: false,
      newUrl: null,
      online: "online",
      filetype: fileType,
      localUrl: null,
      OriginUrl: null,
      editCount: 0,
      onlineUrl: null,
      orginName: null,
      thumbnail: null,
      is_deleted: 0,
      order: currentMediaLength + orderVal,
      updated_at: await GetUTCTime(),
      description: null,
      OriginlocalUrl: null,
      isOriginUploaded: true,
    };
    let img_obj_string = JSON.stringify(imgObj);
    let params = {};
    if (action === "line") {
      params = {
        video: file,
        inspection_guid: sessionStorage.getItem("guid"),
        template_id: templateId,
        video_thumbnail: thumbFile,
        category_id: category_uuid,
        line_id: lineUuid,
        img_object: img_obj_string,
        img_type: "details",
      };
    }
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    let baseUrl = await origin();
    let sessionAPIKey = sessionStorage.getItem("CK");
    let sessionSecretKey = sessionStorage.getItem("SK");
    try {
      const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
          "api-key": `${Base64.decode(sessionAPIKey)}`,
          platform: "web",
        },
      });
      const result = response.data;
      responseCount++;
      successCount++;
      videoUploadCount++;
    } catch (error) {
      console.error(`Error posting data for user:`, error);
      toast.error(error?.message);
      responseCount++;
      errorCount++;
      videoUploadCount++;
    }
    if (responseCount === e.target.files.length) {
      if (successCount > 0)
        toast.success(`${successCount} ${successCount === 1 ? "media item has" : "media items have"} been uploaded successfully. `, {
          duration: 4000,
        });
      if (errorCount > 0)
        toast.error(
          `${
            errorCount - sizeErrorCount > 0
              ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1 ? "item was" : "items were"} not uploaded, the file format is not supported.`
              : ""
          } ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"} not uploaded because the media file is greater than 1024MB.` : ""}`,
          {
            duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
          }
        );
      callInitiateAPI();
      fileInputLine.current.value = null;
      responseCount = 0;
      videoUploadCount = 0;
      imageuploadCount = 0;
      errorCount = 0;
      sizeErrorCount = 0;
      successCount = 0;
    }
  }

  const callInitiateAPI = () => {
    setLoader2(false);
    setLoaderText("");
    let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem("AC"));
    let guid = sessionStorage.getItem("guid");
    let sessionUserId = Base64.decode(sessionStorage.getItem("UI"));
    dispatch(getInspectionData({ guid: guid, associate_company: sessionAssociateCompanyKey, user_id: sessionUserId }));
    setLoader(false);
  };

  async function compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  const handleAddMedia = async (e) => {
    let imageCount = 0;
    let videoCount = 0;
    let currentMediaLength = lineMediaData?.length;
    let fileSizeStatus = [];
    let formatStatus = [];
    setImage(e.target.files);
    for (let i = 1; i <= e.target.files.length; i++) {
      let formData = new FormData();
      let file = e.target.files.item(i - 1);

      const fileSize = file.size;
      const sizeInMB = Math.round(fileSize / (1024 * 1024));
      let fileTypeCheck = null;
      if (file?.type === "image/jpeg" || file?.type === "image/png" || file?.type === "image/jpg") {
        fileTypeCheck = "image";
      } else if (file?.type === "image/tiff" || file?.type === "image/bmp" || file?.type === "image/gif" || file?.type === "image/svg" || file?.type === "image/ico" || file?.type === "image/x-icon") {
        fileTypeCheck = "unsupported";
      } else {
        fileTypeCheck = "video";
      }
      if (fileTypeCheck === "image" || fileTypeCheck === "unsupported") imageCount++;
      if (fileTypeCheck === "video") videoCount++;
    }
    setLoader2(true);
    if (e.target.files.length === 0) setLoader2(false);
    for (let i = 1; i <= e.target.files.length; i++) {
      let formData = new FormData();
      let file = e.target.files.item(i - 1);
      let fileType = null;
      if (file?.type === "image/jpeg" || file?.type === "image/png" || file?.type === "image/jpg") {
        fileType = "image";
      } else if (file?.type === "image/tiff" || file?.type === "image/bmp" || file?.type === "image/gif" || file?.type === "image/svg" || file?.type === "image/ico" || file?.type === "image/x-icon") {
        fileType = "unsupported";
      } else {
        fileType = "video";
      }
      const fileSize = file.size;
      const sizeInMB = Math.round(fileSize / (1024 * 1024));
      if (sizeInMB >= 1024) {
        errorCount++;
        responseCount++;
        sizeErrorCount++;
        if (responseCount === e.target.files.length) {
          if (successCount > 0)
            toast.success(`${successCount} ${successCount === 1 ? "media item has" : "media items have"} been uploaded successfully. `, {
              duration: 4000,
            });
          if (errorCount > 0)
            toast.error(
              `${
                errorCount - sizeErrorCount > 0
                  ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1 ? "item was" : "items were"} not uploaded, the file format is not supported.`
                  : ""
              } ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"} not uploaded because the media file is greater than 1024MB.` : ""}`,
              {
                duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
              }
            );
          callInitiateAPI();
          fileInputLine.current.value = null;
          responseCount = 0;
          videoUploadCount = 0;
          imageuploadCount = 0;
          errorCount = 0;
          sizeErrorCount = 0;
          successCount = 0;
        }
      } else if (!file.type.match(/(image\/(png|jpg|jpeg))|(video\/(mp4|mov|quicktime))/) || file?.name?.toLowerCase()?.endsWith(".jfif")) {
        errorCount++;
        responseCount++;
        if (responseCount === e.target.files.length) {
          if (successCount > 0)
            toast.success(`${successCount} ${successCount === 1 ? "media item has" : "media items have"} been uploaded successfully. `, {
              duration: 4000,
            });
          if (errorCount > 0)
            toast.error(
              `${
                errorCount - sizeErrorCount > 0
                  ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1 ? "item was" : "items were"} not uploaded, the file format is not supported.`
                  : ""
              } ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"} not uploaded because the media file is greater than 1024MB.` : ""}`,
              {
                duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
              }
            );
          callInitiateAPI();
          fileInputLine.current.value = null;
          responseCount = 0;
          videoUploadCount = 0;
          imageuploadCount = 0;
          errorCount = 0;
          sizeErrorCount = 0;
          successCount = 0;
        }
      } else {
        if (fileType === "image") {
          setLoaderText(`compressing image ${imageuploadCount + 1} of ${imageCount}`);
          let compressedFile;
          let thumbImage;
          const { imgWidth, imgHeight } = await getImageDimensions(file);
          const maxWidth = 320;
          const maxHeight = 320;
          const quality = 0.8;
          let newWidth, newHeight;
          if (imgWidth > imgHeight) {
            // Landscape orientation
            newHeight = maxHeight;
            newWidth = (maxHeight / imgHeight) * imgWidth;
          } else {
            // Portrait orientation
            newWidth = maxWidth;
            newHeight = (maxWidth / imgWidth) * imgHeight;
          }
          let thumbnailImage = await resizeImage(file, newWidth, newHeight, quality);
          thumbImage = new File([thumbnailImage], `${thumbnailImage.name}`, { type: "image/jpeg" });
          if (file?.size > 512000) {
            // =====
            try {
              const compressedFilee = await compressImage(file);
              compressedFile = new File([compressedFilee], `${compressedFilee.name}`, { type: "image/jpeg" });
              setLoaderText(`uploading image ${imageuploadCount + 1} of ${imageCount}`);
              let imgObj = {
                id: uuidv4(),
                url: null,
                name: compressedFile.name,
                size: compressedFile.size,
                type: compressedFile.type,
                edited: false,
                newUrl: null,
                online: "online",
                filetype: fileType,
                localUrl: null,
                OriginUrl: null,
                editCount: 0,
                onlineUrl: null,
                orginName: null,
                thumbnail: null,
                is_deleted: 0,
                order: currentMediaLength + i,
                updated_at: await GetUTCTime(),
                description: null,
                OriginlocalUrl: null,
                isOriginUploaded: true,
              };
              let img_obj_string = JSON.stringify(imgObj);
              let params = {};
              if (action === "line") {
                params = {
                  image: compressedFile,
                  video_thumbnail: thumbImage,
                  inspection_guid: sessionStorage.getItem("guid"),
                  template_id: templateId,
                  category_id: category_uuid,
                  line_id: lineUuid,
                  img_object: img_obj_string,
                  img_type: "details",
                };
              }
              Object.keys(params).forEach((key) => formData.append(key, params[key]));
              let baseUrl = await origin();
              let sessionAPIKey = sessionStorage.getItem("CK");
              let sessionSecretKey = sessionStorage.getItem("SK");
              try {
                const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
                    "api-key": `${Base64.decode(sessionAPIKey)}`,
                    platform: "web",
                  },
                });
                const result = response.data;
                responseCount++;
                successCount++;
                imageuploadCount++;
              } catch (error) {
                responseCount++;
                errorCount++;
                imageuploadCount++;
                toast.error(error?.message);
                console.error(`Error posting data for user:`, error);
              }

              if (responseCount === e.target.files.length) {
                if (successCount > 0)
                  toast.success(`${successCount} ${successCount === 1 ? "media item has" : "media items have"} been uploaded successfully. `, {
                    duration: 4000,
                  });
                if (errorCount > 0)
                  toast.error(
                    `${
                      errorCount - sizeErrorCount > 0
                        ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1 ? "item was" : "items were"} not uploaded, the file format is not supported.`
                        : ""
                    } ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"} not uploaded because the media file is greater than 1024MB.` : ""}`,
                    {
                      duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
                    }
                  );
                callInitiateAPI();
                fileInputLine.current.value = null;
                responseCount = 0;
                videoUploadCount = 0;
                imageuploadCount = 0;
                errorCount = 0;
                sizeErrorCount = 0;
                successCount = 0;
              }
            } catch (error) {
              console.error("Error compressing image:", error);
            }
          } else {
            compressedFile = file;
            setLoaderText(`uploading image ${imageuploadCount + 1} of ${imageCount}`);
            let imgObj = {
              id: uuidv4(),
              url: null,
              name: compressedFile.name,
              size: compressedFile.size,
              type: compressedFile.type,
              edited: false,
              newUrl: null,
              online: "online",
              filetype: fileType,
              localUrl: null,
              OriginUrl: null,
              editCount: 0,
              onlineUrl: null,
              orginName: null,
              thumbnail: null,
              is_deleted: 0,
              order: currentMediaLength + i,
              updated_at: await GetUTCTime(),
              description: null,
              OriginlocalUrl: null,
              isOriginUploaded: true,
            };
            let img_obj_string = JSON.stringify(imgObj);
            let params = {};
            if (action === "line") {
              params = {
                image: compressedFile,
                video_thumbnail: thumbImage,
                inspection_guid: sessionStorage.getItem("guid"),
                template_id: templateId,
                category_id: category_uuid,
                line_id: lineUuid,
                img_object: img_obj_string,
                img_type: "details",
              };
            }

            Object.keys(params).forEach((key) => formData.append(key, params[key]));
            let baseUrl = await origin();
            let sessionAPIKey = sessionStorage.getItem("CK");
            let sessionSecretKey = sessionStorage.getItem("SK");
            try {
              const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
                  "api-key": `${Base64.decode(sessionAPIKey)}`,
                  platform: "web",
                },
              });
              const result = response.data;
              responseCount++;
              imageuploadCount++;
              successCount++;
            } catch (error) {
              responseCount++;
              errorCount++;
              imageuploadCount++;
              toast.error(error?.message);
              console.error(`Error posting data for user:`, error);
            }

            if (responseCount === e.target.files.length) {
              if (successCount > 0)
                toast.success(`${successCount} ${successCount === 1 ? "media item has" : "media items have"} been uploaded successfully. `, {
                  duration: 4000,
                });
              if (errorCount > 0)
                toast.error(
                  `${
                    errorCount - sizeErrorCount > 0
                      ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1 ? "item was" : "items were"} not uploaded, the file format is not supported.`
                      : ""
                  } ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"} not uploaded because the media file is greater than 1024MB.` : ""}`,
                  {
                    duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
                  }
                );
              callInitiateAPI();
              fileInputLine.current.value = null;
              responseCount = 0;
              videoUploadCount = 0;
              imageuploadCount = 0;
              errorCount = 0;
              sizeErrorCount = 0;
              successCount = 0;
            }
          }
        } else if (fileType === "video") {
          setLoader2(true);
          setLoaderText(`generating thumbnail for video ${videoUploadCount + 1} of ${videoCount}`);
          await generateVideoThumbnails(file, 10).then(async (thumbs) => {
            await convertBase64ToFile(thumbs, "name", file, formData, fileType, currentMediaLength, i, e, videoCount);
          });
        }
      }
    }
  };

  const activateLine = (uuid) => {
    dispatch(activateLines(uuid));
  };

  const handleEditDescription = (line) => {
    setAddInputChanged(false);
    setEditAadditionalInp(false);
    setShowAddDescriptionModal(true);
    let descriptionValues = line?.line_input_controls?.find((data) => data.type === "Description")?.values;
    setDescriptionArray(descriptionValues !== undefined ? descriptionValues : []);
    setDescriptionArrayCopy(descriptionValues !== undefined ? descriptionValues : []);
  };

  const handleEditAdditionalInput = (line) => {
    setTempSeletedDescrition(selectedDescription);
    setAddInputPresent(true);
    setAddInputChanged(true);
    setEditAadditionalInp(true);
    setCurrentAdditionalInputID(line.line_input_control_id);
    setShowAddDescriptionModal(true);
    let descriptionValues = line?.values;
    setDescriptionArray(descriptionValues !== undefined ? descriptionValues : []);
    setDescriptionArrayCopy(descriptionValues !== undefined ? descriptionValues : []);
    setSelectedDescription(line.data);
    if (line?.data?.length !== 0) setAddInputPresent(true);
    else setAddInputPresent(false);
  };

  const handleDescriptionSelection = async (description) => {
    let tempData;
    if (selectedDescription.length === 0) {
      tempData = descriptionArray;
    } else {
      tempData = JSON.parse(JSON.stringify(descriptionArray));
    }

    if (selectedDescription.length === 0) {
      setSelectedDescription([description]);
    } else {
      let sltdDesc = selectedDescription;
      let sltd_desc = [];

      let desIndex = sltdDesc.findIndex((item) => item === description);
      if (desIndex !== -1) {
        sltd_desc = sltdDesc.filter((prevDescription) => prevDescription !== description);
      } else sltd_desc = [...sltdDesc, description];

      setSelectedDescription(sltd_desc);
    }

    let idx = tempData.findIndex((itm) => itm.value === description);
    if (idx !== -1) {
      tempData[idx].is_selected = tempData[idx].is_selected === true || tempData[idx].is_selected === "true" ? false : true;
      tempData[idx].updated_at = await GetUTCTime();
      setDescriptionArray(tempData);
      setRefresh((prev) => !prev);
    } else {
      if (tempData.findIndex((x) => x !== description)) {
        tempData.push({
          uuid: uuidv4(),
          value: description,
          is_deleted: 0,
          updated_at: await GetUTCTime(),
          is_selected: true,
          created_at: await GetUTCTime(),
        });
        setDescriptionArray(tempData);
        setRefresh((prev) => !prev);
      }
    }
  };

  const getMediaData = (line) => {
    let medData = line?.line_input_controls?.find((data) => data.type === "Media")?.data;
    setLineMediaData(medData);
  };

  const handleMoreIconClick = (event, line, index) => {
    setAssignColorIndicatorLine(line);
    setLineMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setLineMenuAnchorEl(null);
  };

  const handleAssignColor = () => {
    setAssignColorIndicatorModal(true);
  };

  const handleEditLineName = (line) => {
    setShowEditLineModal(true);
    setActiveEditLine(line);
    setEditLineName(line.prompt);
  };

  const handleDeleteLine = (line) => {
    setShowDeleteLineModal(true);
    setActiveDeleteLine(line);
  };

  const handleCloseEditAlert = () => {
    setShowEditLineModal(false);
  };

  const handleCloseDeleteAlert = () => {
    setShowDeleteLineModal(false);
  };

  const handleDuplicateLine = async (line) => {
    let duplicateTemplateData = storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId);
    let CategoryData = duplicateTemplateData?.categories?.find((itm) => itm.id === categoryId);
    let objectToAdd = {
      uuid: uuidv4(),
      prompt: "",
      showLineEmpty: true,
      comments: [],
      optional: 0,
      created_at: GetUTCTime(),
      is_deleted: 0,
      updated_at: "",
      defaultRating_id: 1,
      default_location: null,
      line_input_controls: [],
    };
    CategoryData?.lines.splice(lineIndex + 1, 0, objectToAdd);
    const formData = {
      guid: sessionStorage.getItem("guid"),
      inspection_id: storeData.inspectionData?.data?.inspection_id,
      template_id: templateId,
      category_uuid: category_uuid,
      line_uuid: line?.uuid,
    };
    await ApiCallDuplicateLine(dispatch, formData);
    setLineMenuAnchorEl(null);
    setDuplicateLineHighlighted(true);
    setDuplicateLineIdx(lineIndex + 1);
  };

  const handleConfirmDeleteLine = async () => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines.findIndex((itm) => itm.uuid == activeDeleteLine.uuid);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].is_deleted = 1;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines?.map(async (item, index) => {
      item.order = index + 1;
      item.updated_at = await GetUTCTime();
      return item;
    });
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setShowDeleteLineModal(false);
  };

  const handleConfirmEditLine = async (ediedLine) => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines.findIndex((itm) => itm.uuid == activeEditLine.uuid);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].prompt = ediedLine;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setShowEditLineModal(false);
    setLineMenuAnchorEl(null);
  };

  const handleTogglecomplete = async (line) => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines.findIndex((itm) => itm.uuid == line.uuid);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].is_completed = line?.is_completed === true || line?.is_completed === 1 ? false : true;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setLineMenuAnchorEl(null);
  };
  const handleTogglePrintable = async (line) => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines.findIndex((itm) => itm.uuid == line.uuid);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].is_printable = line?.is_printable === true || line?.is_printable === 1 ? false : true;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setLineMenuAnchorEl(null);
  };

  useEffect(() => {
    getLineDesValue(line);
    let disableCheck = line?.line_input_controls?.filter((lineData) => lineData.type === "Description")[0]?.is_disabled;
    setDescriptionDisable(disableCheck);
  }, [is_saved, categoryData]);

  useEffect(() => {
    getMediaData(line);
  }, []);

  useEffect(() => {
    setLoader2(false);
    setLoaderText("");
    setLoader(false);
  }, [storeData.updatedInspectionData]);

  useEffect(() => {
    if (storeData?.inspectionData && storeData?.duplicateLineResponse) {
      setTimeout(() => {
        setDuplicateLineHighlighted(false);
        setDuplicateLineIdx(0);
      }, 4000);
    }
  }, [storeData?.inspectionData && storeData?.duplicateLineResponse]);

  const menuItemForCategoryAndLines = () => {
    return (
      <Menu
        anchorEl={lineMenuAnchorEl}
        open={isLineMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "rgba(0, 0, 0, 0.01) 0px 4px 4px",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
            padding: "8px 0",
            width: "240px",
          },
        }}
      >
        <MenuItem onClick={() => handleEditLineName(assignColorIndicatorLine)} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <MdOutlineModeEditOutline style={{ fontSize: "20px", color: "#555555" }} />
          <span style={{ fontWeight: "400  !important", fontSize: "14px  !important", color: "#3b3b3b !important" }}>Edit line name</span>
        </MenuItem>
        {templateData?.is_narrative_template === 1 ? (
          ""
        ) : (
          <MenuItem onClick={() => handleDuplicateLine(assignColorIndicatorLine)} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <AiOutlineCopy style={{ fontSize: "20px", color: "#555555" }} />
            <span style={{ fontWeight: "400  !important", fontSize: "14px  !important", color: "#3b3b3b !important" }}>Duplicate line</span>
          </MenuItem>
        )}
        {/* <MenuItem onClick={handleMenuClose} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <AiOutlineArrowRight style={{ fontSize: "20px", color: "#555555" }} />
          <span style={{ fontWeight: "400", fontSize: "14px", color: "#3b3b3b !important" }}>Move or copy category</span>
        </MenuItem> */}
        <Divider sx={{ margin: "8px 0" }} />
        {/* <MenuItem style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px 16px" }}>
          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <DoneIcon style={{ fontSize: "20px", color: "#555555" }} />
            <span style={{ fontWeight: "400  !important", fontSize: "14px  !important", color: "#3b3b3b !important" }}>Mark line complete</span>
          </span>
          <Switch
            size="small"
            checked={assignColorIndicatorLine?.is_completed === 1 || assignColorIndicatorLine?.is_completed === true || checkLineComplete(assignColorIndicatorLine)}
            onChange={() => handleTogglecomplete(assignColorIndicatorLine)}
          />
        </MenuItem> */}
        {templateData?.is_texas !==1 && <><MenuItem style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px 16px" }}>
          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <MdOutlineLocalPrintshop style={{ fontSize: "20px", color: "#555555" }} />
            <span style={{ fontWeight: "400  !important", fontSize: "14px  !important", color: "#3b3b3b !important" }}>Print in report</span>
          </span>
          <Switch
            size="small"
            checked={assignColorIndicatorLine?.is_printable}
            onChange={() => handleTogglePrintable(assignColorIndicatorLine)}
          />
        </MenuItem>
        <Divider sx={{ margin: "8px 0" }} /></>}
        <MenuItem onClick={handleAssignColor} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <IoMdColorPalette style={{ fontSize: "20px", color: "#555555" }} />
          <span style={{ fontWeight: "400  !important", fontSize: "14px  !important", color: "#3b3b3b !important" }}>Assign color</span>
        </MenuItem>
        <Divider sx={{ margin: "8px 0" }} />
        <MenuItem
          onClick={() => handleDeleteLine(assignColorIndicatorLine)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            fontWeight: "400  !important",
            fontSize: "14px !important",
          }}
        >
          <RiDeleteBin6Line style={{ fontSize: "20px", color: "#D53D3D" }} />
          <span>Delete line</span>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <>
      <AddLineDescriptionModal
        descriptionData={descriptionData}
        showAddDescriptionModal={showAddDescriptionModal}
        setShowAddDescriptionModal={setShowAddDescriptionModal}
        setShowCreateDescriptionModal={setShowCreateDescriptionModal}
        descriptionArray={descriptionArray}
        setDescriptionArray={setDescriptionArray}
        selectedDescription={selectedDescription}
        setSelectedDescription={setSelectedDescription}
        handleDescriptionSelection={handleDescriptionSelection}
        showTemperatureModal={showTemperatureModal}
        setShowTemperatureModal={setShowTemperatureModal}
        showTextBoxModal={showTextBoxModal}
        setShowTextBoxModal={setShowTextBoxModal}
        is_saved={is_saved}
        setISSaved={setISSaved}
        templateId={templateId}
        categoryId={categoryId}
        lineUuid={lineUuid}
        editAadditionalInp={editAadditionalInp}
        currentAdditionalInputID={currentAdditionalInputID}
        descriptionPresent={descriptionPresent}
        addInputChanged={addInputChanged}
        addInputPresent={addInputPresent}
        setAddInputChanged={setAddInputChanged}
        showCreateDescriptionModal={showCreateDescriptionModal}
        newEmptyDescription={newEmptyDescription}
        setNewEmptyDescription={setNewEmptyDescription}
      />
      {assignColorIndicatorModal && (
        <AssignColorIndicatorModal
          assignColorIndicatorModal={assignColorIndicatorModal}
          setAssignColorIndicatorModal={setAssignColorIndicatorModal}
          handleAssignColor={handleAssignColor}
          assignColorList={assignColorList}
          templateId={templateId}
          assignColorIndicatorCategory={assignColorIndicatorCategory}
          assignColorIndicatorLine={assignColorIndicatorLine}
          setAssignColorIndicatorLine={setAssignColorIndicatorLine}
          templateData={templateData}
          setLineMenuAnchorEl={setLineMenuAnchorEl}
          action="lines"
        />
      )}
      <CreateNewDescriptionModal
        showCreateDescriptionModal={showCreateDescriptionModal}
        setShowCreateDescriptionModal={setShowCreateDescriptionModal}
        showAddDescriptionModal={showAddDescriptionModal}
        setShowAddDescriptionModal={setShowAddDescriptionModal}
        descriptionArray={descriptionArray}
        setDescriptionArray={setDescriptionArray}
        descriptionArrayCopy={descriptionArrayCopy}
        handleDescriptionSelection={handleDescriptionSelection}
        selectedDescription={selectedDescription}
        descriptionData={descriptionData}
        templateId={templateId}
        categoryId={categoryId}
        lineUuid={lineUuid}
        newEmptyDescription={newEmptyDescription}
        setNewEmptyDescription={setNewEmptyDescription}
        editAadditionalInp={editAadditionalInp}
        currentAdditionalInputID={currentAdditionalInputID}
      />
      <div className="linecontent_body" style={{ display: checkDescriptionDisabled(line) || isNarrative ? "none" : "flex" }}>
        <FormControl sx={{ m: 1, width: "100%", margin: "0" }} variant="outlined">
          <OutlinedInput
            id={`description_${lineIndex + 1}`}
            className={`${descriptionDisable == 1 || isNarrative ? "lineVisibility" : "location-input"}`}
            size="small"
            type="text"
            fullWidth
            autoComplete="off"
            onClick={() => handleEditDescription(line)}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
              },
            }}
            value={addInputChanged ? tempSelectedDescription?.join(", ") : selectedDescription?.join(", ")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton disableRipple aria-label="edit decription" onClick={() => handleEditDescription(line)} edge="end" id={`description_icon_${lineIndex + 1}`}>
                  {<EditIcon />}
                </IconButton>
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            // placeholder="Select Description"
            placeholder={descriptionData === undefined ? "Description" : descriptionData?.prompt === null ? "" : descriptionData?.prompt}
          />
        </FormControl>

        {categoryData?.is_inspection_details !== 1 && (
          <>
            <p
              className={`line_content_comment ${matches ? "responsive" : ""}`}
              id={`add_comment_btn_${lineIndex + 1}`}
              onClick={() => {
                navigate(`/inspection/${categoryData.prompt !== null ? encodeURIComponent(categoryData.prompt) : "Untitled"}/${line.prompt !== null ? encodeURIComponent(line.prompt) : "Untitled"}`, {
                  state: { line, template_id, category_id, category_uuid, line_uuid: lineUuid, target: "comment", add_comment_from_line: true },
                });
                activateLine(line.uuid);
              }}
            >
              <MessageIcon />
              {!matches && <span>Add comment</span>}{" "}
            </p>
            {line?.line_input_controls?.find((data) => data?.type === "Media")?.is_disabled != 1 ? (
              <>
                <p className={`line_content_photo ${matches ? "responsive" : ""}`} onClick={() => fileInputLine.current.click()} id={`add_line_image_btn_${lineIndex + 1}`}>
                  <input ref={fileInputLine} type="file" accept="image/*,video/*" onChange={handleAddMedia} style={{ display: "none" }} multiple />
                  <CameraAltIcon /> {!matches && <span>Add media</span>}
                </p>
                <div className="line__moreicon">
                  <MoreVertIcon sx={{ cursor: "pointer", color: "#9B9B9B", zIndex: 10 }} onClick={(event) => handleMoreIconClick(event, line, lineIndex)} />
                </div>
                {menuItemForCategoryAndLines()}
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
      {/* additional inputs */}
      {!isNarrative ? additionalInputs?.map((item, index) => <AdditionalInputLine line={item} handleEditAdditionalInput={handleEditAdditionalInput} key={index} render={render} />) : ""}
      {/* additional inputus */}
      <DeleteConfirmationDialog
        open={deleteLineModal}
        handleClose={handleCloseDeleteAlert}
        title={"Delete line"}
        contentText={`Are you sure you want to delete the line ${activeDeleteLine?.prompt}?`}
        cancelBtnTitle={"Cancel"}
        cancelBtnAction={handleCloseDeleteAlert}
        submitBtnTitle={"Delete"}
        submitBtnAction={handleConfirmDeleteLine}
      />

      <Modal
        open={showEditLineModal}
        onClose={handleCloseEditAlert}
        BackdropProps={{ style: { opacity: "60%" } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mark-as-complete-modal-main"
      >
        <Box className="mark-as-complete-modal" sx={{ minHeight: "201px !important", top: "40% !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon id="close_edit_modal" sx={{ float: "right", cursor: "pointer", color: "#A5A5A5", mt: 1, mr: 1 }} onClick={handleCloseEditAlert} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "left", marginBottom: "20px", marginLeft: "15px" }}>
              <p className="modal-heading">Edit line name </p>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl variant="standard" className="forms-control" style={{ paddingLeft: "15px" }}>
                <label className="input-form-label" htmlFor="CategoryName">
                  Line Name
                </label>
                <TextField
                  id="line_name"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Line name"
                  type="text"
                  value={editLineName}
                  onChange={(e) => setEditLineName(e.target.value)}
                  style={{ width: "97%" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", textAlign: "center", mt: "14px", mb: "30px", justifyContent: "center", gap: "20px" }}>
              <FilledButton width="95%" text="Save" onClick={() => handleConfirmEditLine(editLineName)} id="edit_save_button" />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default LineDescription;
